
// ATENÇÃO, LEIA ISSO: VIRAR A CHAVE ROTA_OFICIAL NO single.service.ts
// ANTES DE PUBLICAR


// // ********* USO NO AMBIENTE OFICIAL
// export const environment = {
//   production: true,
//   bluveApiUrl: 'https://bluve.com.br/api/v1/',
//   bluveLocalAPI: 'https://us-central1-sira-41b32.cloudfunctions.net/api/v1/',
//   firebaseConfig: {
//     apiKey: 'AIzaSyD6382EgeegLjTkKkXmMBnVxoDjamSTk9c',
//     authDomain: 'sira-41b32.firebaseapp.com',
//     databaseURL: 'https://sira-41b32.firebaseio.com',
//     projectId: 'sira-41b32',
//     storageBucket: 'sira-41b32.appspot.com',
//     messagingSenderId: '322901096086',
//     appId: '1:322901096086:web:0fc268bc95a5545d9774b7',
//     measurementId: 'G-QZM4CS2LWB'
//   },
// };

// ********* USO NO AMBIENTE STAGING
export const environment = {
  production: true,
  bluveApiUrl: 'https://dev.bluve.com.br/api/v1/',
  // bluveLocalAPI: 'http://localhost:5001/bluve-4c-teste/us-central1/api/v1/',
  bluveLocalAPI: 'https://us-central1-bluve-4c-teste.cloudfunctions.net/api/v1/',
  firebaseConfig: {
    apiKey: 'AIzaSyBb8MxslSLv4AnOhWFH2vSJuxMoEi5EeSk',
    authDomain: 'bluve-4c-teste.firebaseapp.com',
    databaseURL: 'https://bluve-4c-teste.firebaseio.com',
    projectId: 'bluve-4c-teste',
    storageBucket: 'bluve-4c-teste.appspot.com',
    messagingSenderId: '219758613774',
    appId: '1:219758613774:web:369b210dbaf37132c6735f',
    measurementId: 'G-ZCPXRYKP6S'
  },
};
