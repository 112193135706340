import { Injectable, DebugElement } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SingleService {

  public VERSAO = 'v. 1.2';
  private ROTA_OFICIAL = true;


  constructor() { }

  public usuarioPhoto: any = 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png';


  getIndicador() {
    return JSON.parse(localStorage.getItem('indicador'));
  }

  referCustomerUrl() {
    const url = this.ROTA_OFICIAL ? environment.bluveApiUrl : environment.bluveLocalAPI;
    return `${url}indicar-cliente`;
  }

  queryUrl() {
    const url = this.ROTA_OFICIAL ? environment.bluveApiUrl : environment.bluveLocalAPI;
    return `${url}consultar-indicacoes`;
  }

}
